import queryString from 'query-string'

export default async function staffLogin ({ next }){
    const userProfile = queryString.parse(location.search.replace("?", ""))

    if(Object.keys(userProfile).length > 0) {
        if(userProfile.mid) {
            // eslint-disable-next-line no-undef
            $cookies.set('STAFF_PROFILE_DATA', JSON.stringify(userProfile), "3h")
            return next()
        }
    }
}