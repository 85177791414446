<template>
<fragment>
    <div v-if="isLoading" class="text-center">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
    <div class="text-center" style="margin-bottom: 90px">
        <div class="preview mb-3" v-if="value">
            <img :src="value">
        </div>
        <div class="wrap">
            <input type="file" name="" @change="onFileChange">
            <button type="button" class="btn btn-upload py-2">{{label}}</button>
        </div>
        <div v-if="!size_valid" class="text-danger font-size-sm">Maximum upload file size is {{this.size}} MB</div>
    </div>
</fragment>
</template>

<script>
import { requestUploadImage } from '@/apis/apis'
export default {
    props: ['size', 'value', 'label'],
    data() {
        return {
            size_valid: true,
            isLoading: false,
        }
    },
    methods: {
        onFileChange(e) {
            const file = e.target.files[0];
            if(file.size > (parseInt(this.size) * 1000000)) {
                this.size_valid = false
                return
            }
            this.size_valid = true
            let reader = new FileReader()
            reader.onload = async (event) => {
                this.isLoading = true
                await requestUploadImage({
                    formData : {
                        img_url: event.target.result,
                    },
                    role: 'employee',
                    cbSuccess : res => {
                        this.$emit('input', res.data.img_url)
                        this.isLoading = false
                    } ,
                    cbError : (_e , msg) => {
                        this.isLoading = false
                        console.log('error:', msg)
                    }
                })
            }
            reader.readAsDataURL(file)
        },
    }
}
</script>

<style scoped>
.wrap {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
}
.wrap input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    width: 100%;
    display: block !important;
}
.wrap input[type=file],
.wrap input[type=file]::-webkit-file-upload-button {
    cursor: pointer; 
}
</style>