import { service } from "./request-index"
import { getToken } from "../utility/help"

const requestAll = async(instance , { cbSuccess , cbError }) => {
    try {
        const res = await instance
        if( res.status === 200 ) {
            cbSuccess(res)
        } else {
            cbError(res , "Error !")
        }
    } catch( e ) {
        console.log(e)
        cbError(null , e)
    }
}

export const requestStaffInfo = ({ bot_id, mid, role, cbSuccess , cbError }) => requestAll(
    service.get(`/api/v1/${bot_id}/employee/mid/${mid}`,
        {
            headers: {
                "YR-mid": mid,
                "YR-role": role,
                "YR-token": getToken(`/api/v1/${bot_id}/employee/mid/${mid}`)
            }
        }
    ) ,
    { cbSuccess , cbError }
)

export const requestStaffLogin = ({ bot_id, formData, mid, role, cbSuccess , cbError }) => requestAll(
    service.post(`/api/v1/${bot_id}/employee/login`,
        formData,
        {
            headers: {
                "YR-mid": mid,
                "YR-role": role,
                "YR-token": getToken(`/api/v1/${bot_id}/employee/login`, formData)
            }
        }
    ) ,
    { cbSuccess , cbError }
)

export const requestUserPoint = ({ bot_id, formData, mid, role, cbSuccess , cbError }) => requestAll(
    service.post(`/api/v1/${bot_id}/user-point`,
        formData,
        {
            headers: {
                "YR-mid": mid,
                "YR-role": role,
                "YR-token": getToken(`/api/v1/${bot_id}/user-point`, formData)
            }
        }
    ) ,
    { cbSuccess , cbError }
)

export const requestStaffInvite = ({ bot_id, formData, mid, role, cbSuccess , cbError }) => requestAll(
    service.post(`/api/v1/${bot_id}/employee/invite`,
        formData,
        {
            headers: {
                "YR-mid": mid,
                "YR-role": role,
                "YR-token": getToken(`/api/v1/${bot_id}/employee/invite`, formData)
            }
        }
    ) ,
    { cbSuccess , cbError }
)

export const requestUserPointVerify = ({ bot_id, formData, mid, role, cbSuccess , cbError }) => requestAll(
    service.post(`/api/v1/${bot_id}/user-point/verify`,
        formData,
        {
            headers: {
                "YR-mid": mid,
                "YR-role": role,
                "YR-token": getToken(`/api/v1/${bot_id}/user-point/verify`, formData)
            }
        }
    ) ,
    { cbSuccess , cbError }
)

export const requestReceiptVerify = ({ bot_id, formData, mid, role, cbSuccess , cbError }) => requestAll(
    service.post(`/api/v1/${bot_id}/receipt/verify`,
        formData,
        {
            headers: {
                "YR-mid": mid,
                "YR-role": role,
                "YR-token": getToken(`/api/v1/${bot_id}/receipt/verify`, formData)
            }
        }
    ) ,
    { cbSuccess , cbError }
)
