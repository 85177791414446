import Vue from "vue";
import VueRouter from "vue-router";

import inviteStaff from './middleware/inviteStaff'
import staffLogin from './middleware/staffLogin'
import staffScan from './middleware/staffScan'
import userLogin from './middleware/userLogin'
import userScan from './middleware/userScan'
import userRegister from './middleware/userRegister'

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
        meta: {
            middleware: [
                userLogin
            ]
        },
    },
    {
        path: "/point/:id",
        name: "Point",
        component: () => import(/* webpackChunkName: "Home" */ "../views/Point.vue"),
        meta: {
            middleware: [
                userScan
            ]
        },
    },
    {
        path: "/instruction",
        name: "Instruction",
        component: () => import(/* webpackChunkName: "Home" */ "../views/Instruction.vue"),
    },
    {
        path: "/login-error",
        name: "Login Error",
        component: () => import(/* webpackChunkName: "Home" */ "../views/LoginError.vue"),
    },
    {
        path: "/register",
        name: "Register",
        component: () => import(/* webpackChunkName: "Home" */ "../views/Register.vue"),
        meta: {
            middleware: [
                userRegister,
            ]
        },
    },
    {
        path: "/close",
        name: "Close",
        component: () => import(/* webpackChunkName: "Home" */ "../views/CloseBotton.vue"),
    },
    {
        path: "/staff",
        name: "Staff",
        component: () => import(/* webpackChunkName: "Staff" */ "../views/staff/Index.vue"),
        meta: {
            middleware: [
                staffScan
            ]
        },
    },
    {
        path: "/staff/login",
        name: "Staff Login",
        component: () => import(/* webpackChunkName: "Staff Login" */ "../views/staff/Login.vue"),
        meta: {
            middleware: [
                staffLogin
            ]
        },
    },
    {
        path: "/staff/invite",
        name: "Staff Invite",
        component: () => import(/* webpackChunkName: "Staff Invite" */ "../views/staff/Invite.vue"),
        meta: {
            middleware: [
                inviteStaff
            ]
        },
    },
    {
        path: "/staff/error",
        name: "Staff Error",
        component: () => import(/* webpackChunkName: "Staff Invite" */ "../views/staff/Error.vue"),
    },
    {
        path: "/404",
        name: "NotFound",
        component: () => import(/* webpackChunkName: "NotFound" */ "../views/NotFound.vue")
    },
    // {
    //     path: "*",
    //     component: () => import(/* webpackChunkName: "NotFound" */ "../views/NotFound.vue")
    // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
