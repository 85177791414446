<template>
    <div class="wrap">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>