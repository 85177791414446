import { service } from "./request-index"
import { getToken } from "../utility/help"

const requestAll = async(instance , { cbSuccess , cbError }) => {
    try {
        const res = await instance
        if( res.status === 200 ) {
            cbSuccess(res)
        } else {
            cbError(res , "Error !")
        }
    } catch( e ) {
        console.log(e)
        cbError(null , e)
    }
}


export const requestUploadImage = ({ formData, mid, role, cbSuccess , cbError }) => requestAll(
    service.post(`/api/upload`,
        formData,
        {
            headers: {
                "YR-mid": mid,
                "YR-role": role,
                "YR-token": getToken(`/api/upload`, formData)
            }
        }
    ) ,
    { cbSuccess , cbError }
)

export const requestLogActivity = ({ formData, role, cbSuccess , cbError }) => requestAll(
    service.post(`/api/v1/log`,
        formData,
        {
            headers: {
                "YR-mid": formData.mid,
                "YR-role": role,
                "YR-token": getToken(`/api/v1/log`, formData)
            }
        }
    ) ,
    { cbSuccess , cbError }
)

