import Vue from "vue";
import { Plugin } from 'vue-fragment'
import VueCookies from 'vue-cookies'

import App from "./App.vue";
import router from "./router";
import store from "./store";
import ComponentRegistration from "./utility/components-registration"
import queryString from 'query-string';

import "@/styles/index.css";

Vue.config.productionTip = false;
Vue.use(Plugin)
Vue.use(VueCookies)
ComponentRegistration(Vue)

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next,
        store
    }
    return middleware[0]({
        ...context
    })
})

async function runner() {
    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app');
}

if (window.location.href.search("liff.state") > -1) {
    localStorage.setItem('LINE_PATH_DATA', decodeURIComponent(window.location.search));
    

    const qsParse = queryString.parse(decodeURIComponent(window.location.search));

    if(qsParse.user_mid) {
        window.location = `${qsParse["liff.state"]}&user_mid=${qsParse.user_mid}`;
    }
    else if(qsParse.code) {
        window.location = `${qsParse["liff.state"]}&code=${qsParse.code}`;
    }
    else if(qsParse.bot_id) {
        window.location = `${qsParse["liff.state"]}&bot_id=${qsParse.bot_id}`;
    }
    else {
        window.location = `${qsParse["liff.state"]}`;
    }
    runner();
}
else {
    runner();
}