import queryString from 'query-string'

// import router from '@/router'

export default async function userLogin ({ next }){
    const userProfile = queryString.parse(location.search.replace("?", ""))

    if(Object.keys(userProfile).length > 0) {
        if(userProfile.mid) {
            // eslint-disable-next-line no-undef
            $cookies.set('USER_PROFILE_DATA', JSON.stringify(userProfile), "3h")
            return next()
        }
        // else {
        //     router.push('/404')
        // }
    }
    // else {
    //     router.push('/404')
    // }

}