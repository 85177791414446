import queryString from 'query-string'

import router from '@/router'

export default async function userRegister (){
    const userProfile = queryString.parse(location.search.replace("?", ""))

    if(Object.keys(userProfile).length > 0) {
        if(userProfile.modular_mid && userProfile.bot_id) {
            window.location.href = `${process.env.VUE_APP_API_END_POINT}/api/line/login?url=${process.env.VUE_APP_REDIRECT_URL}/&modular_mid=${userProfile.modular_mid}&bot_id=${userProfile.bot_id}`
        }
        else {
            router.push('/404')
        }
    }
    else {
        router.push('/404')
    }
}