import queryString from 'query-string'

import router from '@/router'

import { requestUserPointVerify } from '@/apis/staff'

export default async function staffScan ({ next }){
    const userProfile = queryString.parse(location.search.replace("?", ""))

    if(userProfile && userProfile.mid) {
        await requestUserPointVerify({
            bot_id: userProfile.bot_id,
            formData: {
                user_mid: userProfile.user_mid,
                employee_mid: userProfile.mid,
            },
            mid: userProfile.mid,
            role: 'employee',
            cbSuccess: res => {
                if(res.data.data.result) {
                    // eslint-disable-next-line no-undef
                    $cookies.set('STAFF_PROFILE_DATA', JSON.stringify(userProfile), "3h")
                    return next()
                }
                else {
                    router.push('/staff/error')
                }
            } ,
            cbError: () => {
                this.$toast.error('ระบบขัดข้อง')
            }
        })
    }
    else {
        window.location.href = `${process.env.VUE_APP_API_END_POINT}/api/line/login?url=${process.env.VUE_APP_REDIRECT_URL}/staff/&user_mid=${userProfile.user_mid}&bot_id=${userProfile.bot_id}&is_staff=1`
    }
}