import router from '@/router'

export default async function userScan (){
    const botIDQueryString = location.pathname.replace("/point/", "")
    if(botIDQueryString) {
        window.location.href = `${process.env.VUE_APP_API_END_POINT}/api/line/login?url=${process.env.VUE_APP_REDIRECT_URL}/&bot_id=${botIDQueryString}`
    }
    else {
        router.push('/404')
    }
}