import axios from "axios"

export const service = axios.create({
    baseURL: process.env.VUE_APP_API_END_POINT,
    timeout : 5000
})

service.interceptors.request.use(
    config => {
        return config
    } ,
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

export const serviceNodeRed = axios.create({
    baseURL: process.env.VUE_APP_API_NODE_RED,
    timeout : 5000
})

serviceNodeRed.interceptors.request.use(
    config => {
        return config
    } ,
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)