import queryString from 'query-string'

import router from '@/router'

import { requestStaffInvite } from '@/apis/staff'

export default async function  inviteStaff (){
    const inviteData = queryString.parse(location.search)

    if(inviteData.code) {
        await requestStaffInvite({
            bot_id: inviteData.bot_id,
            formData: {
                url: inviteData.code
            },
            role: 'employee',
            cbSuccess: res => {
                if(res.data.data.result) {
                    window.location.href = `${process.env.VUE_APP_API_END_POINT}/api/line/login?url=${process.env.VUE_APP_REDIRECT_URL}/staff/login/&flag=invite&code=${res.data.data.id}&bot_id=${inviteData.bot_id}&is_staff=1`
                }
                else {
                    router.push('/404')
                }
            } ,
            cbError: () => {
                this.$toast.error('ระบบขัดข้อง')
            }
        })
    }
    else {
        router.push('/404')
    } 
}